import React, { Fragment } from "react";
import { graphql } from "gatsby";
import { Content } from "./generic/genericPage.js";
import GenericPostPageWrapper, { GenericPostTemplate } from "./generic/genericPostTemplate";
import DisclaimerText from "../components/DisclaimerText/DisclaimerText";

// type Props = {
// 	page: Page,
// };

const PageContent = ({ page }) => (
	<Fragment>
		<Content content={page.content}/>
		<DisclaimerText>
			* מוצרי האונליין משותפים עם הקונה לזמן מוגבל
		</DisclaimerText>
	</Fragment>);

export const LibraryItemPageTemplate = ({ page }) =>
	<GenericPostTemplate page={page} siteTitle="PREVIEW">
		{PageContent}
	</GenericPostTemplate>;

export default GenericPostPageWrapper({
	type: "ספרייה",
	sectionPath: "/library",
	children: PageContent
});

export const pageQuery = graphql`
    query LibraryByIdQuery($id: String!) {
        ...SiteTitle
        markdownRemark(id: {eq: $id }) {
            id
            html
            frontmatter {
                date(formatString: "MMMM DD, YYYY")
                title
                description
                descriptionRaw
                tags
                templateKey
                postId
            }
            fields {
                slug
            }
            ...RemoteBannerImage
        }
    }`;
